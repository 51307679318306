import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { BookGrid } from '../styles/Grids';
import LoadingGrid from './LoadingGrid';

const BookListItem = styled.li`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled(Link)`
  flex: 1;

  &:hover {
    text-decoration: underline;
  }
`;

const ImageContainer = styled.figure`
  padding: 0;
`;

const TextContainer = styled.div`
  padding: 2rem 1rem;

  h4 {
    /* font-family: var(--primary-font); */
    /* font-weight: 600; */
    margin-top: 0;
  }
`;

function SingleBook({ book }) {
  return (
    <BookListItem>
      <LinkWrapper to={`/book/${book.slug.current}`}>
        <ImageContainer>
          <Img fluid={book.image.asset.fluid} alt="book.name" />
        </ImageContainer>
        <TextContainer>
          <h4>{book.name}</h4>
        </TextContainer>
      </LinkWrapper>
    </BookListItem>
  );
}

export default function BookList({ books }) {
  return (
    <div>
      <h1>Books</h1>
      {!books && <LoadingGrid count={2} />}
      <BookGrid>
        {books.map((book) => (
          <SingleBook key={book.id} book={book} />
        ))}
      </BookGrid>
    </div>
  );
}
