import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const PaginationStyles = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: center;
  border: 1px solid var(--grey-2);
  margin: 2rem 0;
  border-radius: 5px;
  text-align: center;
  margin: 4rem auto;
  background: var(--white);
  max-width: 300px;
  font-family: var(--secondary-font);
  font-size: 1.5rem;

  & > * {
    padding: 1rem;
    flex: 1;
    border-right: 1px solid var(--grey-2);
    text-decoration: none;
    &[aria-current],
    &.current {
      font-weight: 600;
    }
    &[disabled] {
      pointer-events: none;
      color: gray;
    }
  }
`;

export default function Pagination({
  pageSize,
  totalCount,
  currentPage,
  skip,
  base,
}) {
  const totalPages = Math.ceil(totalCount / pageSize);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasNextPage = nextPage <= totalPages;
  const hasPrevPage = prevPage >= 1;
  return (
    <PaginationStyles>
      <Link disabled={!hasPrevPage} to={`${base}/${prevPage}`}>
        Previous
      </Link>
      {Array.from({ length: totalPages }).map((_, i) => (
        <Link to={`${base}/${i > 0 ? i + 1 : ''}`} key={`page${i}`}>
          {i + 1}
        </Link>
      ))}
      <Link disabled={!hasNextPage} to={`${base}/${nextPage}`}>
        Next
      </Link>
    </PaginationStyles>
  );
}
