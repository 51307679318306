import React from 'react';
import { BookGrid } from '../styles/Grids';

export default function LoadingGrid({ count }) {
  return (
    <BookGrid>
      {Array.from({ length: count }, (_, i) => (
        <p>Loading...</p>
      ))}
    </BookGrid>
  );
}
