import React from 'react';
import { graphql } from 'gatsby';
import BookList from '../components/BookList';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import LayoutSection from '../components/LayoutSection';

export default function BooksPage({ data, pageContext }) {
  const books = data.books.nodes;

  return (
    <>
      <SEO title={`Books by `} />
      <Layout>
        <LayoutSection>
          <BookList books={books} />
          <Pagination
            pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
            totalCount={data.books.totalCount}
            currentPage={pageContext.currentPage || 1}
            skip={pageContext.skip}
            base="/books"
          />
        </LayoutSection>
      </Layout>
    </>
  );
}

export const query = graphql`
  query BookQuery($skip: Int = 0, $pageSize: Int = 16) {
    books: allSanityBook(
      limit: $pageSize
      skip: $skip
      sort: { fields: [order], order: [ASC] }
    ) {
      totalCount
      nodes {
        name
        descriptionExcerpt
        id
        order
        slug {
          current
        }
        bookDescription
        publications
        reviews {
          source
          reviewText
        }
        image {
          asset {
            fixed(width: 200, height: 200) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
